// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body.save-as-modal-body {
  min-width: 500px;
}

wfm-plan-loading-overlay {
  top: 0;
  bottom: 0;
  position: absolute;
  left: -1px;
  right: 0;
  background: rgba(0, 0, 0, 0.0784313725);
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-save-as/plan-save-as.component.scss","webpack://./../PSR%20ESP%20WebApp%20Build/src/app/pages/plan/plan-save-as/plan-save-as.component.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;ACAF;;ADIA;EACE,MAAA;EACA,SAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,uCAAA;EACA,UAAA;ACDF","sourcesContent":["\n.modal-body.save-as-modal-body {\n  min-width: 500px;\n}\n\n\nwfm-plan-loading-overlay{\n  top: 0;\n  bottom: 0;\n  position: absolute;\n  left: -1px;\n  right: 0;\n  background: #00000014;\n  z-index: 1;\n}\n",".modal-body.save-as-modal-body {\n  min-width: 500px;\n}\n\nwfm-plan-loading-overlay {\n  top: 0;\n  bottom: 0;\n  position: absolute;\n  left: -1px;\n  right: 0;\n  background: rgba(0, 0, 0, 0.0784313725);\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
