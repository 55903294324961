// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
  font-family: "Open Sans", Arial, sans-serif;
  color: #212e36;
  margin-bottom: 0;
  height: 22px;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/components/page-title/page-title.component.scss","webpack://./../PSR%20ESP%20WebApp%20Build/src/app/components/page-title/page-title.component.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;ACCF","sourcesContent":[".page-title {\n  font-family: \"Open Sans\", Arial, sans-serif;\n  color: #212e36;\n  margin-bottom: 0;\n  height: 22px;\n  font-size:18px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n",".page-title {\n  font-family: \"Open Sans\", Arial, sans-serif;\n  color: #212e36;\n  margin-bottom: 0;\n  height: 22px;\n  font-size: 18px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
