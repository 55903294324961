import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ElementRef} from "@angular/core";

import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ValidationResult, WfmTextboxComponent} from "../wfm-textbox/wfm-textbox.component";
import {SelectItem, WfmSelectComponent} from "../wfm-select/wfm-select.component";
import { Features, CreatePlanRequestV2} from "../../models/plan";
import {FormBuilder, FormGroup, FormControl} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {Observable, Subscription} from "rxjs";
import {CreatePlanService} from "./create-plan-v2.service";
import {DatePipe, FormatWidth, getLocaleDateFormat} from "@angular/common";
import {appSettings} from "src/app/app-settings";
import { AuthenticationService } from "src/app/authentication.service";
import { PlanHelper } from "src/app/helpers/plan-helper";
import { Store } from "@ngrx/store";
import  * as appActions from "src/app/store/actions";
import {AppActionTypes} from "src/app/store/actions";
import { Actions, ofType } from "@ngrx/effects";
import { MultiSelect } from "primeng/multiselect";
import { WeekPickerComponent } from "../week-picker/week-picker.component";

@Component({
  selector: "create-plan",
  templateUrl: "./create-plan-v2.component.html",
  styleUrls: ["./create-plan-v2.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CreatePlanService, DatePipe]
})
export class CreatePlanComponentV2 implements OnInit, AfterViewInit, OnDestroy {

  private state$: Observable<any>;
  private token: String;
  private createPlanStep:CreatePlanStep = CreatePlanStep.STEP1;

  protected subscriptionList: Array<Subscription> = [];

  MUType = MULabel;
  selectedMUType:MULabel = MULabel.MUS;
  startDOW = 0;

  createPlanForm: FormGroup;
  minDate:Date;
  maxDate:Date;
  modelValue:Date;
  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private planService: CreatePlanService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private store: Store<any>,
    private authService: AuthenticationService,
    private action$: Actions) {
      this.maxDate = new Date(Date.now());
      if(this.maxDate.getDay()!=6)
        this.maxDate.setDate(this.maxDate.getDate() - (this.maxDate.getDay() + 1));
      this.minDate = new Date(this.maxDate);
      this.minDate.setDate(this.minDate.getDate() - 6 - 51*7);
      //debugger;
      let defaultDate = new Date(this.maxDate);
      defaultDate.setDate(defaultDate.getDate()-6);
      defaultDate.setHours(0, 0, 0, 0);
      this.planModel = {
        planName: "", entityType: "", entity: "", forecastSource: "", forecast: "", mus: [], muset: null,
        week1: defaultDate,
        week2: defaultDate,
        week3: defaultDate,
        week4: defaultDate
      }

  }

  createPlan() {
    let result = this.submit();
    this.close(result);
  }

  get isStep1(){
    return (this.createPlanStep === CreatePlanStep.STEP1);
  }

  get isStep2(){
    return (this.createPlanStep === CreatePlanStep.STEP2);
  }

  get isFeatureToggleEnabledForImportForecast() {
    return (this.authService.hasFeature(Features.IMPORTED_FORECASTS_MENU));
  }
  get isMuSetValid(){
    if(this.planModel.muset && this.planModel.muset.hasPermissionOnAllMus===true){
      return true;
    }
    return false;
  }
  continue(){
    this.createPlanStep = CreatePlanStep.STEP2;
  }
  back(){
    this.createPlanStep = CreatePlanStep.STEP1;
  }

  radioChangeHandler(event){
    this.selectedMUType = event;
    this.planModel.muset = null;
    this.planModel.mus = [];
    this.updateSelectedMUs(null);
  }


  muItems = [];
  muSets = [];


  updateSelectedMUs(event){
    this.muItems.forEach((item)=>{
      let found = this.planModel.mus.indexOf(item.value);
      item.isSelected=found>-1;
    });
  }

  get defaultLabel(){
    return this.translate.instant("radio."+this.selectedMUType);
  }

  close(result) {
    // console.log("close");
    this.activeModal.close(result);
  }

  dismiss() {
    // console.log("dismiss");
    this.activeModal.dismiss();
  }

  private labels = {
    planName: "Plan Name",
    entityType: "Entity Type",
    entity: "Entity",
    forecastLabel: "Long-Term Forecast"
  };

  private labelKeys = {
    planName: "plan.label.name",
    entityType: "plan.label.entitytype",
    entity: "plan.label.entity",
    forecastSource: "plan.label.forecastsource",
    forecastLabel: "plan.label.forecast",
    muSetLabel: "radio.label.muset",
    weekPickerLabel: "plan.label.weeks"
  };

  entityTypeList:string[] = [];
  entityTypeKeys: string[] = ["ct", "ctset", "bu", "buset", "eg", "egset"];

  forecastSourceList:string[] = [];
  forecastSourceKeys:string[] = ["iex", "imp"];

  clearable = false;
  planModel: CreatePlanRequestV2;
  selectedEntity: SelectItem = null;
  selectedForecast: SelectItem = null;
  isEntityUpdated = false;

  @ViewChild("planName") planNameEl: WfmTextboxComponent;
  @ViewChild("entityType") entityTypeEl: WfmSelectComponent;
  @ViewChild("entity") entityEl: WfmSelectComponent;
  @ViewChild("forecastSource") forecastSourceEl: WfmSelectComponent;
  @ViewChild("forecast") forecastEl: WfmSelectComponent;

  @ViewChild("muselect") muSelect: MultiSelect;

  @ViewChild("week1") week1Picker: WeekPickerComponent;
  @ViewChild("week2") week2Picker: WeekPickerComponent;
  @ViewChild("week3") week3Picker: WeekPickerComponent;
  @ViewChild("week4") week4Picker: WeekPickerComponent;


  ngOnInit(): void {
    this.createPlanForm = this.fb.group({});//selectedItems: new FormControl()
    this.entityTypeList = this.entityTypeKeys.map((item)=>{
      return this.translate.instant("plan.label.entity."+item);
    });
    this.forecastSourceList = this.forecastSourceKeys.map((item)=>{
      return this.translate.instant("plan.forecast.source."+item);
    })
    this.initHandlers();
  }

  ngAfterViewInit(): void {
    this.planNameEl.validators.push(this.planNameValidator.bind(this));
  }

  getLabelByFieldName(fieldName: string): Observable<any> {
    return this.translate.get(this.labelKeys[fieldName]);

  }

  getEntityLabel(): Observable<any> {
    var index = this.entityTypeList.indexOf(this.planModel.entityType);
    var entityTypeKey = this.entityTypeKeys[index];
    if (entityTypeKey) {
      return this.translate.get(this.labelKeys["entity"] + "." + entityTypeKey);
    } else {
      return this.translate.get(this.labelKeys["entity"]);
    }

  }

  setPlanName(str: string) {

    this.planModel.planName = str;

  }

  hasValidPlanName() {
    return this.planModel.planName.trim() !== "";
  }

  checkingUniquePlanName = false;

  checkPlanNameExists(event: any) {
    this.checkingUniquePlanName = true;
    const planName = event.target.value;
    const successHandler = (res: any) => {
      this.planNameEl.serverSideErrMsgs = [];
      if (res.status === 200) {
        this.planNameEl.serverSideErrMsgs.push(this.translate.instant("plan.not.unique"));
      }// returns 204 if unique
      this.planNameEl.wfmTextboxEvent.emit(event);
      this.checkingUniquePlanName = false;
    }
    const failHandler = (err: any) => {
      //this.dismiss();
      this.store.dispatch(new appActions.UnrecoverableError(err));

    }
    if (this.hasValidPlanName())
      this.planService.planExists(planName).subscribe(successHandler, failHandler);
  }

  get entityTypeDisabled() {
    const enable = this.hasValidPlanName();
    if (!enable && this.entityTypeEl) {
      this.planModel.entityType = null;
      this.entityTypeEl.clear();
    }
    return !enable;
  }


  _entityDisabled = true;

  get entityDisabled() {
    const enable = this.planModel.entityType != null && this.planModel.entityType !== "" && !this.entityTypeDisabled;
    if (!enable && this.entityEl) {
      this.entityEl.label = this.translate.instant(this.labelKeys.entity);
      this.planModel.entity = "";
      this.entityEl.clear();
    }
    this._entityDisabled = !enable;
    return this._entityDisabled;
  }

  _forecastSourceDisabled = true;
  get forecastSourceDisabled() {
    const enable = this.planModel.entity != null && this.planModel.entity !== "";
    if (!enable && this.forecastSourceEl) {
      this.planModel.forecastSource = "";
      this.forecastSourceEl.clear();
    }
    this._forecastSourceDisabled = !enable;
    return this._forecastSourceDisabled;
  }

  _forecastDisabled = true;
  get forecastDisabled() {
    const enable = this.planModel.entity !== "" && this.planModel.entity !== null && this.planModel.forecastSource !== null;
    if (!enable && this.forecastEl) {
      this.planModel.forecast = "";
      this.forecastEl.clear();
    }
    this._forecastDisabled = !enable;
    return this._forecastDisabled;
  }

  _currEntityList: Array<SelectItem> = new Array<SelectItem>();

  get entityList() {
    return this._currEntityList;
  }

  updateEntityType(entityType) {
    if (this.planModel.entityType !== entityType) {
      this._entityDisabled = true;

      this.planModel.entityType = entityType;
      this.loadEntityList();
      this.updateEntity(null);
    }

  }

  _entityListLoading = false;
  get entityListLoading() {
    return this._entityListLoading;
  }

  loadEntityList() {

    var index = this.entityTypeList.indexOf(this.planModel.entityType);
    var entityTypeKey = this.entityTypeKeys[index];
    if (entityTypeKey) {
      console.log(environment.espEndpointBaseUrl + appSettings.resourceLocations.entityList + `/${entityTypeKey}`);

      this._entityListLoading = true;
      const successHandler = (data: any) => {
        this._currEntityList = new Array<SelectItem>();
        data.entityList.forEach(e => {
          var selectLabel: string = e.id + "  " + e.name;
          var item: SelectItem = {label: selectLabel, value: e.id, object: e};
          this._currEntityList.push(item);

        });
        this._entityListLoading = false;
        this.entityEl.showLoadingIcon(false);
      };
      const errorHandler = (error: any) => {
        console.log(error);
        if (error.status === 500 || error.status === 401) {
          this.store.dispatch(new appActions.UnrecoverableError(error));
          this.dismiss();
        }
      }; // error path

      this.planService.getEntityList(entityTypeKey).subscribe(successHandler, errorHandler);
    }
  }

  updateEntity(p: SelectItem) {
    this.selectedEntity = p;
    var entity = p ? p.object : null;
    if (this.planModel.entity !== entity) {
      this.planModel.entity = entity;
      if (this.planModel.entity) {
        // At the moment we select by default IEX as forecast source.
        this.isEntityUpdated = true;
        this.updateForecastSource(this.forecastSourceList[0]);
      }
    } else {
      this.isEntityUpdated = false;
    }

  }

  updateForecastSource(forecastSource) {
    if (this.planModel.forecastSource !== forecastSource
      || this.isEntityUpdated) {
      this._forecastSourceDisabled = true;
      this.planModel.forecastSource = forecastSource;
      if (this.planModel.forecastSource) {

        var indexForecastSource = this.forecastSourceList.indexOf(this.planModel.forecastSource);
        var forecastSourceKey = this.forecastSourceKeys[indexForecastSource];

        var indexEntityType = this.entityTypeList.indexOf(this.planModel.entityType);
        var entityTypeKey = this.entityTypeKeys[indexEntityType];
        var entityOid = this.planModel.entity.oid;

        let dateShortFormat = "M/d/yy";
        if (this.translate.currentLang) {
          dateShortFormat = getLocaleDateFormat(this.translate.currentLang, FormatWidth.Short);
        }
        if (forecastSourceKey === 'iex') {
          this.loadForecastList(entityTypeKey, entityOid, dateShortFormat);
        } else {
          // Imported forecasts.
          this.loadImportedForecast(entityTypeKey, entityOid, dateShortFormat);
        }
        this.loadMuAndMuset();
      }
    }
    this.updateForecast(null);
  }

  _currForecastList: Array<SelectItem> = new Array<SelectItem>();

  get forecastList() {
    return this._currForecastList;
  }

  errorHandlerLongTermForecast(error: any) {
    console.log(error);
    if(error.status===401){
      this.store.dispatch(new appActions.LTFRetrieveFail(error));
      this.dismiss();
    } else if (error.status === 404) {
      this._currForecastList = new Array<SelectItem>();
      this.forecastListLoading = false;
      this.forecastEl.showLoadingIcon(false);
    } else if (error.status === 500) {
      this.store.dispatch(new appActions.UnrecoverableError(error));
      this.dismiss();
    }
  }

  getLTFLabelForAnElement(name: string, startDate: string, endDate: string): string {
    let fcLabel = name + ` (${startDate}`;
    if (startDate !== "" && endDate !== "") {
      fcLabel = fcLabel + " - ";
    }
    return fcLabel = fcLabel + `${endDate})`;
  }

  forecastListLoading = false;
  loadForecastList(entityTypeKey: string, entityOid: string, dateShortFormat: string) {
    if (entityTypeKey && entityOid) {
      console.log(environment.espEndpointBaseUrl + appSettings.resourceLocations.forecastList + `/${entityTypeKey}` + `/${entityOid}`);
      this.forecastListLoading = true;
      const successHandler = (data: any) => {
        this._currForecastList = new Array<SelectItem>();
        data.ltfcastEntries.forEach(e => {
          let startDate = (e.startDate !== null) ? this.datePipe.transform(new Date(e.startDate), dateShortFormat, "UTC +0000") : "";
          let endDate = (e.endDate !== null) ? this.datePipe.transform(new Date(e.endDate), dateShortFormat, "UTC +0000") : "";
          let fcLabel = this.getLTFLabelForAnElement(e.name, startDate, endDate);
          const item: SelectItem = {label: fcLabel, value: e.oid, object: e};
          this._currForecastList.push(item);
        });
        this.forecastListLoading = false;
        this.forecastEl.showLoadingIcon(false);
      };
      this.planService.getLTForecastList(entityTypeKey, entityOid).subscribe(successHandler,
        this.errorHandlerLongTermForecast.bind(this));
    }
  }

  loadImportedForecast(entityTypeKey: string, entityOid: string, dateShortFormat: string) {
    if (entityTypeKey && entityOid) {
      this.forecastListLoading = true;
      const successHandlerForImportedForecastList = (data: any) => {
        this._currForecastList = new Array<SelectItem>();
        data.importForecastEntryList.forEach(e => {
          let startDate = (e.startDate !== null) ? this.datePipe.transform(new Date(e.startDate), dateShortFormat, "UTC +0000") : "";
          let endDate = (e.endDate !== null) ? this.datePipe.transform(new Date(e.endDate), dateShortFormat, "UTC +0000") : "";
          let longTermForecastLabel = this.getLTFLabelForAnElement(e.forecastName, startDate, endDate);
          const item: SelectItem = {label: longTermForecastLabel, value: e.oid, object: e};
          this._currForecastList.push(item);
        });
        this.forecastListLoading = false;
        this.forecastEl.showLoadingIcon(false);
      };
      this.planService.getImportedForecastList(entityTypeKey, entityOid)
      .subscribe(
        successHandlerForImportedForecastList,
        this.errorHandlerLongTermForecast.bind(this)
      );
    }
  }

  loadMuAndMuset(){
    const index = this.entityTypeList.indexOf(this.planModel.entityType);
    const entityTypeKey = this.entityTypeKeys[index];
    const entityOid = this.planModel.entity.oid;
    this.planService.getMUsAndMUsets(entityTypeKey, entityOid);
  }

  updateForecast(event: SelectItem) {
    this.selectedForecast = event;
    var ltf = event ? event.object : null;
    if (this.planModel.forecast !== ltf) {
      this._forecastDisabled = true;
      this.planModel.forecast = ltf;
    }
  }

  updateMUSet(item: SelectItem){
    const muset = item? item.object: null;
    this.planModel.muset = muset;
  }

  getWeekStartDate(){
    if(this.planModel.forecast)
      new Date(this.planModel.forecast.startDate);
  }

  updateWeek(event:any, week){
    switch (week) {
      case 1:
        this.planModel.week1 = event;
        break;
      case 2:
        this.planModel.week2 = event;
        break;
      case 3:
        this.planModel.week3 = event;
        break;
      case 4:
        this.planModel.week4 = event;
        break;
      default:
        break;
    }
  }

  hasDuplicateWeeks(){
    const weeks = [this.planModel.week1+"",
                    this.planModel.week2+"",
                    this.planModel.week3+"",
                    this.planModel.week4+""].filter(x => x != "null");
    const weekSet = new Set(weeks);
    return weeks.length > weekSet.size?true:false
  }

  checkFormValidationStatus() {

  }

  get hasValidStep1(){
    const uniquePlanName = this.planNameEl && (this.planNameEl.serverSideErrMsgs.length == 0);
    const validPlanName = PlanHelper.planNameRegEx.test(this.planModel.planName) && uniquePlanName;
    const validEntityType = this.planModel.entityType !== null && this.planModel.entityType.length > 1;
    const validEntity = this.planModel.entity !== null;
    const validForecastSource = this.planModel.forecastSource !== null;
    const validForecast = this.planModel.forecast !== null;
    const res = (validPlanName && validEntityType && validEntity && validForecastSource && validForecast);
    return res;
  }

  get hasValidForm() {
    const validMuMuSet = (this.planModel.mus !== null && this.planModel.mus.length > 0) || this.isMuSetValid;
    const valid4Weeks = this.planModel.week1 !== null
                        && this.planModel.week2 !== null
                        &&this.planModel.week3 !== null
                        &&this.planModel.week4 !== null;
    const noDuplicateWeeks = !this.hasDuplicateWeeks();
    const res = (this.hasValidStep1 && validMuMuSet && valid4Weeks && noDuplicateWeeks);
    return res;
  }

  get enableCreatePlan() {
    const res = this.hasValidForm && this.hasValidStep1;
    return res
  }

  get enableContinue() {
    return this.hasValidStep1 && !this.planNameEl.hasFocus && !this.checkingUniquePlanName;
  }

  planNameValidator(name: string) {

    if (name.length < 1) {
      return new ValidationResult(this.translate.instant("field.required.msg"));
    } else if (name.length > 32) {
      return new ValidationResult("Maximum 32 characters"); //can't be. input has a limit
    } else if (!PlanHelper.planNameRegEx.test(name)) {
      return new ValidationResult(this.translate.instant("plan.name.invalid"));
    } else {
      return new ValidationResult("");
    }
  }


  submit() {
    const index = this.entityTypeList.indexOf(this.planModel.entityType);
    const entityTypeKey = this.entityTypeKeys[index].toLocaleUpperCase();
    const sDate = new Date(this.planModel.forecast.startDate);
    const eDate = new Date(this.planModel.forecast.endDate);
    const weeks = [];
    let indexForecastSource = this.forecastSourceList.indexOf(this.planModel.forecastSource);
    let forecastSourceKey = this.forecastSourceKeys[indexForecastSource];
    let forecastName =  this.planModel.forecast.name;
    let forecastOid = this.planModel.forecast.oid;
    if (forecastSourceKey === 'imp') {
      forecastName =  this.planModel.forecast.forecastName;
    }
    [this.planModel.week1, this.planModel.week2, this.planModel.week3, this.planModel.week4].forEach(week => {
      const date = new Date(week);
      weeks.push(this.datePipe.transform(date, "yyyy-MM-dd", "UTC +0000"));
    });
    const createPlanRequest: any = {
      planName: this.planModel.planName,
      entityType: entityTypeKey,
      entityId: this.planModel.entity.id,
      entityOid: this.planModel.entity.oid,
      entityName: this.planModel.entity.name,
      ltfcstName: forecastName,
      ltfcstOid: forecastOid,
      forecastSource: forecastSourceKey,
      startDate: this.planModel.forecast.startDate,//this.datePipe.transform(sDate, "yyyy-MM-dd", "UTC +0000"),
      endDate: this.planModel.forecast.endDate,//this.datePipe.transform(eDate, "yyyy-MM-dd", "UTC +0000"),
      planGeneration: 2,
      muStaffingWeekDates: weeks,
    };
    if(this.planModel.forecast.workFlow){
      createPlanRequest.workflowOid = this.planModel.forecast.workFlow.oid;
    }
    if (this.selectedMUType === MULabel.MUS) {
      const mus: any = [];
      this.planModel.mus.forEach(mu => {
        mus.push({ id: mu.id, oid: mu.oid, name: mu.name })
      });
      createPlanRequest.mus = mus;
    } else {
      const muSet = {
        id: this.planModel.muset.id,
        oid: this.planModel.muset.oid,
        name: this.planModel.muset.name
      };
      createPlanRequest.muSets = [muSet];
    }

    this.store.dispatch(new appActions.CreatePlan(createPlanRequest));
  }

  muAndMusetReceivedHandler(response: any) {
    const payload = response.payload;
    this.muItems = [];
    this.muSets = [];
    this.planModel.mus = [];
    this.selectedMUType = this.MUType.MUS;
    this.planModel.muset = null;
    let prevMu = payload.mus[0];
    for (const mu of payload.mus) {
      const label = mu.id + " " + mu.name;
      const separator = prevMu.preferred === true && mu.preferred === false;
      this.muItems.push({ label, value: {...mu,isSelected:false}, separator});
      prevMu = mu;
    }
    let prevMuSet = payload.muSets[0];
    for (const muset of payload.muSets) {
      const label = muset.id + " " + muset.name
      const separator = prevMuSet.preferred === true && muset.preferred === false;
      let styleClass = "";
      if(muset.preferred){
        styleClass = "wfm-bg-white";
      }else if(!muset.preferred){
        styleClass = "wfm-bg-gray";
        styleClass += separator?"-separator":"";
      }
      const item: SelectItem = {label, value: muset.oid, object: {...muset,isSelected: false }, styleClass};
      this.muSets.push(item);
      prevMuSet = muset;
    }

    this.muSelect._options.set(this.muItems);
  }

  muAndMusetRetrieveErrorHandler(error: any) {
    console.log("Create Plan Failed to load MUs:" + error);
    if (error.status === 500 || error.status === 401) {
      this.store.dispatch(new appActions.UnrecoverableError(error));
      this.dismiss();
    }
  }

  getMUsTooltip():string{
    const MAX_TOOLTIP_LABELS = 10
    let tooltip: string = ``;
    const len = this.planModel.mus.length;
    for (let i = 0; i < len && i < MAX_TOOLTIP_LABELS; i++) {
      const mu = this.planModel.mus[i];
      const label = mu.id + " " + mu.name;
      tooltip += label;
      tooltip += i < len - 1 ? `\n` : "";
    }
    if (len > MAX_TOOLTIP_LABELS)
      tooltip += "...+" + (len - MAX_TOOLTIP_LABELS) + this.translate.instant("multiselect.tooltip.more");
    return tooltip;
  }


  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.MuAndMusetReceived))
        .subscribe(this.muAndMusetReceivedHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.MuAndMusetRetrieveError))
        .subscribe(this.muAndMusetRetrieveErrorHandler.bind(this))
    );
  }


  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  public ngOnDestroy() {
    this.clearSubscriptionList();
  }

  getLanguage() {
    let locale = this.translate.currentLang;
    if(locale) {
      return locale.split("-")[0];
    } else {
      return "en";
    }
  }
}

enum CreatePlanStep{
  STEP1, STEP2
}

export enum MULabel{
  MUS ="label.mus",
  MUSET = "label.muset"
}


