import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { appSettings } from "./app-settings";
import { ApiCredentialResponse } from "./models/api-credential";



@Injectable({
  providedIn: "root"
})
export class TenantService {
  private _tenantData: any = null;

  constructor(private http: HttpClient) { }
  public get subdomain(){
    return window.location.host.split(".")[0].toLowerCase();
  }

  public get isCXOne(){
    return this.subdomain === "cxone";
  }
  //tanantName is optional, if not provided, it will use the current tenantName from subdomain
  async validate(tenantName?: string) {
    this._tenantData = null;
    const subdomain = tenantName || this.subdomain;

    if (subdomain === "cxone") {
      return { subdomain };
    }

    try {
      const data = await this.callValidateTenant(subdomain).toPromise();
      this._tenantData = {
        ...data,
        subdomain
      };
      return this._tenantData;
    } catch (err) {
      this._tenantData = null;
      throw err;
    }
  }

  callValidateTenant(subdomain) {
    let apiURL = environment.saasEndpointBaseUrl + "/tenant-manager/v1/tenants/validate?application=ESP&subdomain=" + subdomain;
    return this.http.get(apiURL);
  }

  get tenantData(): any {
    return this._tenantData;
  }

  get tenantIdentifier(): any {
    return this._tenantData?.tenantIdentifier;
  }
}
