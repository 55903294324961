import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Actions, ofType } from "@ngrx/effects";
import { MessageService } from "primeng/api";
import { AppActionTypes, ImportForecastViewLog, ImportForecastViewLogSuccess, ImportForecastViewLogFailed } from 'src/app/store/actions';
import { ViewLogHelper } from './helper/viewlog-helper';
import { AuthenticationService } from 'src/app/authentication.service';
import { Features } from 'src/app/models/plan';

@Component({
  selector: 'wfm-esp-imported-forecasts-view-log',
  templateUrl: './imported-forecasts-view-log.component.html',
  styleUrls: ['./imported-forecasts-view-log.component.scss'],
  providers: [MessageService]
})
export class ImportedForecastsViewLogComponent implements OnInit, OnDestroy {

  private _isLoading = true;
  protected subscriptionList: Array<Subscription> = [];
  _modalTitle = "";
  _errorMessage = "";
  _showErrorMessage = false;
  _showLog = false;
  _msgType = "";
  _ctErrors = "";
  _validations = "";
  _timestamp = "";

  @Input() importedForecastOid: string;
  @Input() importedForecastName: string;

  constructor(public activeModal: NgbActiveModal, private store: Store<any>,
    private action$: Actions, private messageService: MessageService,
    private translate: TranslateService, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.initHandlers();
    this.viewLog();
  }

  close(result) {
    this.activeModal.close(result);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  viewLog() {
    let payload = { "importedForecastOid": this.importedForecastOid };
    this.store.dispatch(new ImportForecastViewLog(payload));
  }

  viewLogSuccess(action: ImportForecastViewLogSuccess) {
    if (action.payload.entries && action.payload.entries[0]) {

      this.addLogMessagesToTextArea(action.payload);
      this._timestamp = action.payload.entries[0].timeStamp;

      this._modalTitle = this.translate.instant("import.forecast.view.log.title.successfully");
      this._msgType = "info";
      this._isLoading = false;
      this._showLog = true;
    } else {
      this.viewLogFailed(new ImportForecastViewLogFailed({}));
    }
  }

  viewLogFailed(action: ImportForecastViewLogFailed) {
    this._modalTitle = this.translate.instant("import.forecast.view.log.title.failed");
    this._errorMessage = this.translate.instant("import.forecast.view.log.error");
    this._errorMessage = this._errorMessage.replace("<forecastName>", this.importedForecastName);
    this._msgType = "error";
    this._showErrorMessage = true;
    this._isLoading = false;
  }

  addLogMessagesToTextArea(data: any) {
    let logMessage: string;
    for (let entry of data.entries) {
      logMessage = undefined;

      // 1) Get the log message according to the key.
      switch (entry.key) {
        case "import.forecast.validation.key.repeated":
        case "import.forecast.validation.shrinkage.valid.values":
        case "import.forecast.validation.mseff.valid.values":
        case "import.forecast.validation.occupancy.valid.values":
        case "import.forecast.validation.asa.valid.values":
        case "import.forecast.validation.asa.valid.values.wkld":
        case "import.forecast.validation.sl.time.required":
        case "import.forecast.validation.sl.time.required.wkld":
        case "import.forecast.validation.sl.time.valid.values":
        case "import.forecast.validation.sl.time.valid.values.wkld":
        case "import.forecast.validation.sl.percent.required":
        case "import.forecast.validation.sl.percent.required.wkld":
        case "import.forecast.validation.sl.percent.valid.values":
        case "import.forecast.validation.sl.percent.valid.values.wkld":
        case "import.forecast.validation.aht.valid.values":
        case "import.forecast.validation.contacts.received.valid.values":
        case "import.forecast.validation.ctid.valid.values":
        case "import.forecast.validation.not.null":
        case "import.forecast.syntax.validation":
        case "import.forecast.validation.cxone.key.repeated":
        case "import.forecast.validation.sl.or.occ.required.wkld": {
          const message = this.translate.instant(entry.key);
          logMessage = ViewLogHelper.replaceParamsFromMessage(message, entry.args);
          if (logMessage) this.addLogMessageToValidations(logMessage);
          break;
        }
        

        case "import.forecast.validation.sl.required.wkld": {
          if (!this.authenticationService.hasFeature(Features.UPDATE_FORECAST_TEMPLATE)) break;
          const message = this.translate.instant(entry.key);
          logMessage = ViewLogHelper.replaceParamsFromMessage(message, entry.args);
          if (logMessage) this.addLogMessageToValidations(logMessage);
          break;
        }
        case "import.forecast.user.no.permission":
        case "import.forecast.user.no.permission.workldnonworkld":
        case "import.forecast.user.permission.failure":
        case "import.forecast.user.permission.failure.workldnonworkld":
        case "import.forecast.validation.s3.success":
        case "import.forecast.validation.date.range.exceeded":
        case "import.forecast.validation.s3.failed":
        case "import.forecast.user.has.permission.for":
        case "import.forecast.user.no.wemskills":
        case "import.forecast.blank.wemskill.name":
        case "import.forecast.blank.ct.id": {
          const message = this.translate.instant(entry.key);
          logMessage = ViewLogHelper.replaceParamsFromMessage(message, entry.args);
          if (logMessage) this.addLogMessageToCtErrors(logMessage);
          break;
        }
        case "import.forecast.user.failure.wemskillnames":
        case "import.forecast.user.has.wemskills": {
          let key = entry.key;
          const message = this.translate.instant(key);
          logMessage = ViewLogHelper.replaceParamsFromMessageWithNewlines(message, entry.args);
          if (logMessage) this.addLogMessageToCtErrors(logMessage);
          break;
        }
        case "import.forecast.no.cts": {
          logMessage = this.translate.instant(entry.key);
          if (logMessage) this.addLogMessageToCtErrors(logMessage);
          break;
        }
        default: {
          console.log("The key [" + entry.key + "] was not found for viewlog.");
          logMessage = undefined;
        }
      }
    }
  }

  addLogMessageToValidations(logMessage: string) {
    if (logMessage) {
      logMessage = (this._validations !== "") ? "\n" + logMessage : logMessage
      this._validations = this._validations + logMessage;
    }
  }

  addLogMessageToCtErrors(logMessage: string) {
    if (logMessage) {
      logMessage = (this._ctErrors !== "") ? "\n" + logMessage : logMessage
      this._ctErrors = this._ctErrors + logMessage;
    }
  }

  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastViewLogSuccess))
        .subscribe(this.viewLogSuccess.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastViewLogFailed))
        .subscribe(this.viewLogFailed.bind(this))
    );

  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  public ngOnDestroy() {
    this.clearSubscriptionList();
  }
}
