// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-wrapper button {
  height: 22px;
  width: 22px;
  padding: 0;
  border: 1px solid #c1bebe;
  margin-right: -96px;
  font-size: 10px;
  border-radius: 3px;
  min-width: 0;
}

::ng-deep .popover.reset-popup .button-response button {
  margin-right: 0;
  margin-left: 10px;
}

::ng-deep .popover.reset-popup .message-text p {
  color: black;
}

::ng-deep .popover.reset-popup {
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-distribution/week-header-v2/week-header-v2.component.scss","webpack://./../PSR%20ESP%20WebApp%20Build/src/app/pages/plan/plan-distribution/week-header-v2/week-header-v2.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;ACCF;;ADEA;EACE,YAAA;EACA,WAAA;EACA,UAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;ACCF;;ADEA;EACG,eAAA;EACA,iBAAA;ACCH;;ADCA;EACE,YAAA;ACEF;;ADIA;EACE,gBAAA;ACDF","sourcesContent":[".header-wrapper{\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.header-wrapper button{\n  height: 22px;\n  width: 22px;\n  padding: 0;\n  border: 1px solid #c1bebe;\n  margin-right: -96px;\n  font-size: 10px;\n  border-radius: 3px;\n  min-width: 0;\n}\n\n::ng-deep .popover.reset-popup .button-response button{\n   margin-right: 0;\n   margin-left: 10px;\n}\n::ng-deep .popover.reset-popup .message-text p{\n  color:black\n\n}\n::ng-deep .popover.reset-popup .arrow::after{\n    //border-top-color: white;\n}\n::ng-deep .popover.reset-popup {\n  max-width: 300px;\n}\n",".header-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.header-wrapper button {\n  height: 22px;\n  width: 22px;\n  padding: 0;\n  border: 1px solid #c1bebe;\n  margin-right: -96px;\n  font-size: 10px;\n  border-radius: 3px;\n  min-width: 0;\n}\n\n::ng-deep .popover.reset-popup .button-response button {\n  margin-right: 0;\n  margin-left: 10px;\n}\n\n::ng-deep .popover.reset-popup .message-text p {\n  color: black;\n}\n\n::ng-deep .popover.reset-popup {\n  max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
