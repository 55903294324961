// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  font-size: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.container {
  background-color: #E1EAF2;
  border: 1px solid #009EDA;
  box-shadow: 5px 10px #888888;
}

div.main {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/error/error.component.scss","webpack://./../PSR%20ESP%20WebApp%20Build/src/app/pages/error/error.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;ACCJ;;ADEA;EACI,yBAAA;EACA,yBAAA;EACA,4BAAA;ACCJ;;ADEA;EACI,YAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;ACAJ","sourcesContent":[".center{\n    font-size: 36px;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\ndiv.container {\n    background-color: #E1EAF2;\n    border: 1px solid #009EDA;\n    box-shadow: 5px 10px #888888;\n}\n\ndiv.main{\n    height: 100%;\n    \n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n}",".center {\n  font-size: 36px;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\ndiv.container {\n  background-color: #E1EAF2;\n  border: 1px solid #009EDA;\n  box-shadow: 5px 10px #888888;\n}\n\ndiv.main {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
