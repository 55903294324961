// This file is used by Jenkins as a template for dynamically generating
// the environment file from command-line parameters. This file is only
// used if an `--environment` parameter is set to `spanish`.
export const environment = {
  production: false,
  saasEndpointBaseUrl: "https://api.psr1.saas.nicewfmdev.com",
  espEndpointBaseUrl: "https://api.psr1.esp.nicewfmdev.com",
  espHelpUrl: "undefined",
  locales: [
    {locale: "en-US", display: "English (US)", dateFormat: "M/d/yyyy", shortDateFormat: "M/d"},
    {locale: "en-US-Dummy", display: "English Dummy (US)", dateFormat: "M/d/yyyy", shortDateFormat: "M/d"},
    {locale: "es-ES", display: "Español (España)", dateFormat: "dd/MM/yyyy", shortDateFormat: "dd/MM"},
    {locale: "fr-FR", display: "Français (France)", dateFormat: "dd/MM/yyyy", shortDateFormat: "dd/MM"},
    {locale: "pt-BR", display: "Português (Brasil)", dateFormat: "dd/MM/yyyy", shortDateFormat: "dd/MM"},
    {locale: "en-US-cxone", display: "English (US-CXone)", dateFormat: "M/d/yyyy", shortDateFormat: "M/d"}
  ],
  defaultLocale: "en-US"
};
