import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {currentPlanDetailSelector} from '../../../store';
import {map, take, takeUntil} from 'rxjs/operators';
import {
  AppActionTypes, ApplyCTSettings,
  GetCTSettings,
  TaskCancel,
  TaskComplete,
  TaskError
} from '../../../store/actions';
import {Subject} from 'rxjs';
import {Actions, ofType} from '@ngrx/effects';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { Location } from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {DialogOptions} from '../../../common/dialog-options';
import {WfmModalComponent as modalComponent} from '../../../components/wfm-modal/wfm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CanComponentDeactivate} from '../../../helpers/can-deactivate-guard.service';
import {AuthenticationService} from '../../../authentication.service';
import {Features} from '../../../models/plan';
import {CtSettings} from '../../../models/ct-settings';

@Component({
  selector: 'esp-ct-settings',
  templateUrl: './ct-settings.component.html',
  styleUrls: ['./ct-settings.component.css']
})
export class CtSettingsComponent implements OnInit, OnDestroy {
  public planName: string;
  public planDetails: any;
  private unsubscribe$: Subject<void> = new Subject();

  selectedCTs = [];
  contactTypesList: any;

  _isLoading = true;
  _isLoadingError = false;
  private navigationState: any;
  refreshTabs = false;
  allCtSettings: CtSettings;
  mergedCtSettings={
    operatingHours:{},
    fteHoursPerDay:null,
    fteHoursPerWeek:null,
    startingBacklog: null,
    workloadCt: null,
    fteDaysPerMonth: {
      JANUARY: null,
      FEBRUARY: null,
      MARCH: null,
      APRIL: null,
      MAY: null,
      JUNE: null,
      JULY: null,
      AUGUST: null,
      SEPTEMBER: null,
      OCTOBER: null,
      NOVEMBER: null,
      DECEMBER: null
    }
  };
  _isOperatingHoursValid = true;
  _isBacklogValid = true;

  private dirtyCTs= new Set();
  displayTabData = false;
  _isPristine: boolean = false;
  planDetailsSaved = false;
  _displayMultiSelectWarning: any = false;
  protected task: any;
  _enableFteSettingsFeature = false;
  _isFteSettingsValid = true;

  constructor(private store: Store<any>,
              private action$: Actions,
              private location: Location,
              private messageService: MessageService,
              protected translate: TranslateService,
              private modalService: NgbModal,
              private authService: AuthenticationService) {
    this.navigationState = location.getState();
  }

  get isValidSettings(){
    return this._isOperatingHoursValid && this._isBacklogValid && this._isFteSettingsValid;
  }

  ngOnInit(): void {
    this.initHandlers();

    this.planDetailsSaved = this.navigationState?.saved;
    this._enableFteSettingsFeature = this.authService.hasFeature(Features.FTE_SETTINGS);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initHandlers() {
    this.store
      .pipe(
        select(currentPlanDetailSelector),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.currentPlanChanged.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.PlanDetailReceivedV2),
        map((detail: any) => detail.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.currentPlanChanged.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GetCTSettingsSuccess),
        map((action: any) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.getCTSettingsSuccess.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GetCTSettingsFailed),
        map((action: any) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.getCTSettingsFailed.bind(this));

    this.action$
      .pipe(
          ofType(AppActionTypes.ApplyCTSettingsSuccess),
          map((action: any) => action.payload),
          takeUntil(this.unsubscribe$)
      )
      .subscribe(this.applyCTSettingsSuccess.bind(this));

    this.action$
      .pipe(
          ofType(AppActionTypes.ApplyCTSettingsFailed),
          map((action: any) => action.payload),
          takeUntil(this.unsubscribe$)
      )
      .subscribe(this.applyCTSettingsFailed.bind(this));

    this.action$.pipe(takeUntil(this.unsubscribe$), ofType(
      AppActionTypes.TaskCreateAction,
      AppActionTypes.TaskStartAction,
      AppActionTypes.TaskStatusAction,
      AppActionTypes.TaskErrorAction,
      AppActionTypes.TaskCancelAction,
      AppActionTypes.TaskCompleteAction)
    ).subscribe(this.taskHandler.bind(this))


  }

  currentPlanChanged(planDetails) {
    // debugger;
    if (planDetails) {
      this.planName = planDetails.planName;
      this.planDetails = planDetails;
      this.getContactTypesList();
      // console.log("plan-details", planDetails);
      this.loadCtSettings();
    }
  }

  getCTSettingsSuccess(data) {
    // console.debug("get CT settings success:", data);
    this._isLoading = false;
    this._isPristine = true;
    this._isOperatingHoursValid = true;
    this._isFteSettingsValid = true;
    this._isBacklogValid = true;

    this.allCtSettings = JSON.parse(JSON.stringify(data.ctSettings));
    this.mergeSelectedCTs();
    this.updateDisplayTabData();
    this.updateWarningState();
  }

  getCTSettingsFailed(error) {
    // console.debug("get CT settings failure:", error);
    this._isLoading = false;
    this._isLoadingError = true;
  }


  private async applyCTSettingsSuccess(){
    this._isPristine=true;
    this.planDetailsSaved = false;
    let successMsg = this.translate.instant("plan.ct.settings.apply.success");
    this.messageService.add({ severity: "success", detail: successMsg});
    this._isPristine = true;
  }

  private async applyCTSettingsFailed(e){
    this.task=null;
    this.messageService.add({ severity: "error", detail: this.translate.instant("plan.ct.settings.apply.error") });
  }

  async showDiscardDlg() {
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.ct.settings.discard.dlg.title";
    dgOption.messageKey = "plan.ct.settings.discard.dlg.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";

    try {
      await modalComponent.showModalMessage(dgOption, this.modalService);
      this.loadCtSettings();
    }catch(e){
      //user cancelled
    }
  }

  updateSelectedCTs($event: any) {
    this.contactTypesList.forEach((ct)=> {
      ct.isSelected = this.selectedCTs.indexOf(ct.value) != -1;
    });

    this.refreshTabs = true;
  }

  ctSelectorClosed() {
    // NOTE: appears to be a bug in primeng v14 where onPanelHide event triggers twice

    if (this.refreshTabs) {
      this.refreshTabs = false;
      this.mergeSelectedCTs();
      this.updateDisplayTabData();
    }
    this.updateWarningState();
  }

  private getContactTypesList() {
    let planCT = this.navigationState?.ct;
    // console.debug("navigationState", this.navigationState);
    this.contactTypesList = this.planDetails.ctInfo?.map((ct)=>{
      let isSelected = ct.oid===planCT || planCT==="ALL_CONTACT_TYPES";
      let item = {
        value: {...ct,label:this.translate.instant("label.ct.short") + " - " + ct.id + " " + ct.name},
        isSelected
      }
      if(isSelected){
        this.selectedCTs.push(item.value);
      }
      return item;
    });
  }

  mergeSelectedCTs() {
    this.mergedCtSettings = {
      operatingHours:{},
      fteHoursPerDay:null,
      fteHoursPerWeek:null,
      startingBacklog: null,
      workloadCt: null,
      fteDaysPerMonth: {
        JANUARY: null,
        FEBRUARY: null,
        MARCH: null,
        APRIL: null,
        MAY: null,
        JUNE: null,
        JULY: null,
        AUGUST: null,
        SEPTEMBER: null,
        OCTOBER: null,
        NOVEMBER: null,
        DECEMBER: null
      }
    };

    let singleCt = this.selectedCTs.length == 1;
    this.selectedCTs.map(ct=>ct.oid).forEach((ctOid)=>{
      this.mergeOperatingHours(this.allCtSettings[ctOid].operatingHours);
      this.mergeFteSettings(this.allCtSettings[ctOid]);
      if (singleCt) {
        this.mergedCtSettings.startingBacklog = this.allCtSettings[ctOid].startingBacklog
        this.mergedCtSettings.workloadCt = this.allCtSettings[ctOid].workloadCt
      }
    });
  }

  mergeOperatingHours(ctOperatingHours){
    Object.keys(ctOperatingHours).forEach((key)=>{
      if(this.mergedCtSettings.operatingHours[key]!=undefined && this.mergedCtSettings.operatingHours[key]!==ctOperatingHours[key]){
        this.mergedCtSettings.operatingHours[key] = "--:--";
      }else{
        this.mergedCtSettings.operatingHours[key] = ctOperatingHours[key]
      }
    })
  }

  private updateDisplayTabData() {
    this.displayTabData = this.selectedCTs.length>0;
  }

  public onBacklogChange({value,isValid}) {
    this._isBacklogValid = isValid;
    this._isPristine = false;
    if (isValid && this.selectedCTs.length == 1) {
      const oid = this.selectedCTs[0].oid;
      this.dirtyCTs.add(oid);
      this.mergedCtSettings.startingBacklog = value;
      this.allCtSettings[oid].startingBacklog = value;
    }
  }

  onOperatingHoursChange ({ model, isValid }) {
    this._isPristine = false;
    this._isOperatingHoursValid = isValid;
    this.selectedCTs.map(ct=>ct.oid).forEach((ctOid)=>{
      this.setOperatingHours(ctOid,this.allCtSettings[ctOid],model);
    });
  }

  private setOperatingHours(ctOid,ct, values) {
    Object.keys(values).forEach((day)=>{
      if(values[day]!="--:--" && ct.operatingHours[day]!==values[day]){
        this.dirtyCTs.add(ctOid);
        ct.operatingHours[day]=values[day];
      }
    });
  }

  applyChanges() {
    let payload = {
      planId:this.planDetails.planId
    };
    this.dirtyCTs.forEach((ctId:any)=>{
      payload[ctId] = {...this.allCtSettings[ctId]};
    });

    this.store.dispatch(new ApplyCTSettings(payload));
  }

  loadCtSettings() {
    this._isLoading = true;
    this.store.dispatch(new GetCTSettings({planId: this.planDetails.planId}));
  }

  async canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
                      nextState: RouterStateSnapshot) {
    // let plan details parent page handle prompting for unsaved changes in place of unapplied changes (if any)
    if (this.isLeavingPlanDetailsWithUnsavedChanges(nextState)) {
      return true;
    }

    if (this._isPristine && this.isValidSettings) {
      return true;
    }

    // Unapplied changes dialog setup
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.exit.unapplied.dialog.title";
    dgOption.messageKey = "plan.exit.unapplied.dialog.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";

    try {
      await modalComponent.showModalMessage(dgOption, this.modalService);
      return true;
    } catch(error) {
      console.debug("user chose not to leave CT settings view");
      return false;
    }
  }

  private isLeavingPlanDetailsWithUnsavedChanges(nextState: RouterStateSnapshot): boolean {
    if (this.planDetailsSaved === false && !nextState?.url?.includes("site-plans")) {
      return true;
    }

    return false;
  }

  @HostListener("window:beforeunload", ["$event"])
  canCloseTab($event: any) {
      if (!this._isPristine) {
          $event.returnValue = true;
      }
  }

  ctSelectorOpened() {

  }

  private updateWarningState() {
    this._displayMultiSelectWarning = this.selectedCTs.length>1;
  }

  private taskHandler(taskAction:any) {
    // console.log("Handler: ", taskAction);
    if (taskAction instanceof TaskComplete) {
      this.task = null;
    } else if(taskAction instanceof  TaskCancel) {
      this.task = null;
    }else if(taskAction instanceof TaskError){
      this.task = null;
    } else {
      this.task = taskAction.payload;
    }
  }

  get _task() {
    return this.task;
  }

  get opHrsOverlayMsg() {
    return {
      image: '2',
      getOverlayMsg: () => {
        return this.translate.instant("plan.ct.settings.operating.hours.select.cts.msg");
      }
    }
  }

  get fteSettingsOverlayMsg() {
    return {
      image: '2',
      getOverlayMsg: () => {
        return this.translate.instant("plan.ct.settings.fte.select.cts.msg");
      }
    }
  }


  private mergeFteSettings({fteHoursPerDay, fteHoursPerWeek,fteDaysPerMonth}) {
    if(this.mergedCtSettings.fteHoursPerDay===fteHoursPerDay || this.mergedCtSettings.fteHoursPerDay===null){
      this.mergedCtSettings.fteHoursPerDay = fteHoursPerDay;
    }else{
      this.mergedCtSettings.fteHoursPerDay = "--:--";
    }

    if(this.mergedCtSettings.fteHoursPerWeek===fteHoursPerWeek || this.mergedCtSettings.fteHoursPerWeek===null) {
      this.mergedCtSettings.fteHoursPerWeek = fteHoursPerWeek;
    }else{
      this.mergedCtSettings.fteHoursPerWeek = "--:--";
    }

    Object.keys(this.mergedCtSettings.fteDaysPerMonth).forEach((month)=>{
      if(this.mergedCtSettings.fteDaysPerMonth[month]===null || this.mergedCtSettings.fteDaysPerMonth[month]===fteDaysPerMonth[month]) {
        this.mergedCtSettings.fteDaysPerMonth[month] = fteDaysPerMonth[month];
      }else {
        this.mergedCtSettings.fteDaysPerMonth[month] = "--";
      }
    });
  }

  onFteSettingsChange({fteHoursPerDay,fteHoursPerWeek,isValid}) {
    this._isPristine = false;
    this._isFteSettingsValid = isValid;
    this.selectedCTs.map(ct=>ct.oid).forEach((ctOid)=>{
      this.dirtyCTs.add(ctOid);
      if(fteHoursPerDay){
        this.allCtSettings[ctOid].fteHoursPerDay = fteHoursPerDay;
      }
      if(fteHoursPerWeek){
        this.allCtSettings[ctOid].fteHoursPerWeek = fteHoursPerWeek;
      }
    });
  }
}
