import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  input,
  Input,
  OnInit,
  output,
  Output,
  ViewChild
} from '@angular/core';
import {TimePickerComponent} from '@niceltd/cxone-components/date-time-pickers';
import {DateUtils} from '../../../../common/utils/date';
import {Features} from '../../../../models/plan';
import {AuthenticationService} from '../../../../authentication.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

const now = new Date();

@Component({
  selector: 'esp-fte-settings',
  templateUrl: './fte-settings.component.html',
  styleUrls: ['./fte-settings.component.css']
})
export class FteSettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('hrsPerDayPicker', { static: false }) hrsPerDayPicker: TimePickerComponent;
  @ViewChild('hrsPerWeekPicker', { static: false }) hrsPerWeekPicker: TimePickerComponent;

  modelChanged = output<{ fteHoursPerDay: any; fteHoursPerWeek: any; isValid: any }>();
  ctSettings = input.required<{
    fteHoursPerDay:number|string,
    fteHoursPerWeek:number|string,
    fteDaysPerMonth:{
      JANUARY: number|string,
      FEBRUARY: number|string,
      MARCH: number|string,
      APRIL: number|string,
      MAY: number|string,
      JUNE: number|string,
      JULY: number|string,
      AUGUST: number|string,
      SEPTEMBER: number|string,
      OCTOBER: number|string,
      NOVEMBER: number|string,
      DECEMBER: number|string
    }
  }>();

  hrsPerDayStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  hrsPerDayEndDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  hrsPerDayModel = {
    value: '00:00', isValid: true,validationMessage:''
  }

  hrsPerWeekStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  hrsPerWeekEndDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
  hrsPerWeekModel = {
    value: '01:00', isValid: true,validationMessage: ''
  }

  hrsPerWeekStep = 30;
  protected _enableFteMonthFeature: boolean = false;
  public monthsForm: FormGroup;

  get monthMaxDays() {
    return {
      JANUARY: 31,
      FEBRUARY: 29,
      MARCH: 31,
      APRIL: 30,
      MAY: 31,
      JUNE: 30,
      JULY: 31,
      AUGUST: 31,
      SEPTEMBER: 30,
      OCTOBER: 31,
      NOVEMBER: 30,
      DECEMBER: 31
    }
  }
  monthType = {
    JANUARY: 'text',
    FEBRUARY: 'text',
    MARCH: 'text',
    APRIL: 'text',
    MAY: 'text',
    JUNE: 'text',
    JULY: 'text',
    AUGUST: 'text',
    SEPTEMBER: 'text',
    OCTOBER: 'text',
    NOVEMBER: 'text',
    DECEMBER: 'text'
  }
  get months(): string[] {
    return [
      'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
      'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
    ];
  }

  constructor(private authService: AuthenticationService,private fb: FormBuilder, private translateService: TranslateService) {
    this._enableFteMonthFeature = this.authService.hasFeature(Features.FTE_SETTINGS_MONTH);
    this.monthsForm = this.createMonthsFormGroup();
    effect(() => {
      this.setFteSettings(this.ctSettings());

    });
  }

  ngOnInit(): void {

  }

  createMonthsFormGroup(): FormGroup{
    let formControls = {

    }

    this.months.map(month => {
      formControls[month] = this.fb.control( '--',{validators:[
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.max(this.monthMaxDays[month]),
        Validators.min(1)
      ],
      updateOn: 'change'
      });
    });

    return this.fb.group(formControls)
  }


  ngAfterViewInit(): void {
    this.initHrsPerDayPicker();
    this.initHrsPerWeekPicker();
  }

  initHrsPerDayPicker() {
    /*
     * I will go on the record as saying I'm not proud of the following hack, but IIWII.
     * This is a workaround to remove the 00:00 option as well as add 24:00 to the time options
     */
    let options = JSON.parse(JSON.stringify(this.hrsPerDayPicker.timeOptions[0]));
    options.label = "24:00";
    options.diff = "24:00";
    options.datetime = new Date(options.datetime);
    options.datetime.setHours(options.datetime.getHours() + 24);

    // adds 24:00 to the end of the time options
    this.hrsPerDayPicker.timeOptions.push(options);
    // removes 00:00 from the time options
    this.hrsPerDayPicker.timeOptions.shift();
    // console.log("time options:", this.hrsPerDayPicker.timeOptions);
  }

  initHrsPerWeekPicker() {
    for (let hours = 24; hours <= 168; hours++) {
      let options = JSON.parse(JSON.stringify(this.hrsPerWeekPicker.timeOptions[0]));
      const label = `${String(hours).padStart(2, '0')}:00`;
      const diff = `${String(hours).padStart(2, '0')}:00`;
      options.label = label;
      options.diff = diff;
      options.datetime = new Date(options.datetime);
      options.datetime.setHours(options.datetime.getHours() + hours);
      this.hrsPerWeekPicker.timeOptions.push(options);

      if (hours < 168) {
        let halfHourOptions = JSON.parse(JSON.stringify(this.hrsPerWeekPicker.timeOptions[0]));
        const halfHourLabel = `${String(hours).padStart(2, '0')}:${this.hrsPerWeekStep}`;
        const halfHourDiff = `${String(hours).padStart(2, '0')}:${this.hrsPerWeekStep}`;
        halfHourOptions.label = halfHourLabel;
        halfHourOptions.diff = halfHourDiff;
        halfHourOptions.datetime = new Date(halfHourOptions.datetime);
        halfHourOptions.datetime.setHours(halfHourOptions.datetime.getHours() + hours);
        halfHourOptions.datetime.setMinutes(halfHourOptions.datetime.getMinutes() + this.hrsPerWeekStep);
        this.hrsPerWeekPicker.timeOptions.push(halfHourOptions);
      }
    }

    // removes 00:00 from the time options
    this.hrsPerWeekPicker.timeOptions.shift();
    // removes 00:30 from the time options
    this.hrsPerWeekPicker.timeOptions.shift();
    this.hrsPerWeekPicker.writeValue = ()=>{
      //must override the write value function to set a time string bigger than 24:00
    }
    // console.log("hrsPerWeekPicker-timeOptions:", this.hrsPerWeekPicker.timeOptions);
  }

  onHoursPerDayChange($event: any) {
    let value = this.hrsPerDayPicker.selectedTimeString;
    let {isValid,validationMessage} = this.getHoursPerDayValidation(value);
    console.log("onHoursPerDayChange:",value, isValid, validationMessage);
    if (isValid === true) {
      this.hrsPerDayModel.value = value;
    }else{
      this.hrsPerDayModel.validationMessage = validationMessage;
    }
    this.hrsPerDayModel.isValid = isValid;
    this.hrsPerDayPicker.isValidTime = isValid;

    this.notifyModelChange();
  }

  onHoursPerWeekChange($event: any) {
    let value = this.hrsPerWeekPicker.selectedTimeString;
    let {isValid,validationMessage} = this.getHoursPerWeekValidation(value);
    console.log("onHoursPerWeekChange:",value, isValid, validationMessage);
    if (isValid === true) {
      this.hrsPerWeekModel.value = value;
    }else{
      this.hrsPerWeekModel.validationMessage = validationMessage;
    }
    this.hrsPerWeekModel.isValid = isValid;
    this.hrsPerWeekPicker.isValidTime = isValid;

    this.notifyModelChange();


  }
  // onHoursPerWeekChange($event: any, day:string) {
  //   console.log("onHoursPerWeekChange:", $event, day);
  // }


  setFteSettings(ctSettings: { fteHoursPerDay: number | string; fteHoursPerWeek: number | string , fteDaysPerMonth: any}) {
    if(!ctSettings) return;



    if (ctSettings.fteHoursPerDay === "--:--") {
      this.hrsPerDayModel.value = "--:--";
    } else {
      this.hrsPerDayModel.value = DateUtils.convertMinutesToTimeString(ctSettings.fteHoursPerDay as number);
    }
    this.hrsPerDayModel.isValid = true;

    if(ctSettings.fteHoursPerWeek === "--:--") {
      this.hrsPerWeekModel.value = "--:--";
    } else {
      this.hrsPerWeekModel.value = DateUtils.convertMinutesToTimeString(ctSettings.fteHoursPerWeek as number);
    }
    this.hrsPerWeekModel.isValid = true;

    Object.keys(this.monthType).forEach((month)=>{
      this.monthType[month] = 'text';
    });

    setTimeout(()=>{
      this.hrsPerDayPicker.selectedTimeString = this.hrsPerDayModel.value;
      this.hrsPerWeekPicker.selectedTimeString = this.hrsPerWeekModel.value;
      this.monthsForm.reset(ctSettings.fteDaysPerMonth);
    },0);
  }

  notifyModelChange() {
    let isValid = this.hrsPerDayModel.isValid && this.hrsPerWeekModel.isValid;

    let model = {
      fteHoursPerDay:  this.hrsPerDayModel.isValid && this.hrsPerDayModel.value!== "--:--" ? DateUtils.convertTimeStringToMinutes(this.hrsPerDayModel.value):undefined,
      fteHoursPerWeek: this.hrsPerWeekModel.isValid && this.hrsPerWeekModel.value!== "--:--" ?DateUtils.convertTimeStringToMinutes(this.hrsPerWeekModel.value):undefined
    };
    this.modelChanged.emit({
      fteHoursPerDay: model.fteHoursPerDay,
      fteHoursPerWeek: model.fteHoursPerWeek,
      isValid: isValid
    });
  }

  getHoursPerDayValidation(value: string) {
    const [hoursStr, minutesStr] = value.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);


    // Check if hoursStr and minutesStr contain only numeric characters
    if (!/^\d+$/.test(hoursStr) || !/^\d+$/.test(minutesStr)) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (isNaN(hours) || isNaN(minutes)) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (hours < 0 || hours > 24) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.per.day.validation' };
    }

    // Check if both hours and minutes are present and are exactly two digits
    if (!hoursStr || !minutesStr || hoursStr.length !== 2 || minutesStr.length !== 2) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (minutes < 0 || minutes >= 60) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (hours === 0 && minutes < 15) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.per.day.validation' };
    }

    if (hours === 24 && minutes !== 0) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.per.day.validation' };
    }

    if (minutes % 5 !== 0) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.intervals5min.validation' };
    }

    return { isValid: true, validationMessage: '' };
  }
  getHoursPerWeekValidation(value: string) {
    const [hoursStr, minutesStr] = value.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);


    // Check if hoursStr and minutesStr contain only numeric characters
    if (!/^\d+$/.test(hoursStr) || !/^\d+$/.test(minutesStr)) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (isNaN(hours) || isNaN(minutes)) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (hours < 1 || hours > 168) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.per.week.validation' };
    }

    if (!hoursStr || !minutesStr || hoursStr.length < 2 || hoursStr.length > 3 || minutesStr.length !== 2) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (minutes < 0 || minutes >= 60) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.time.format.validation' };
    }

    if (hours === 0 && minutes < 15) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.per.week.validation' };
    }

    if (hours === 168 && minutes !== 0) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.per.week.validation' };
    }

    if (minutes % 5 !== 0) {
      return { isValid: false, validationMessage: 'plan.ct.settings.fte.hours.intervals5min.validation' };
    }

    return { isValid: true, validationMessage: '' };
  }

  onMonthEdit(month: string) {
    this.monthType[month] = 'number';
  }

  getMonthName(number: number) {
    return this.translateService.instant("primeng.monthNames")[number];
  }
}
