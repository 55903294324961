// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-ag-header {
  display: flex;
  flex-flow: row nowrap;
}
.custom-ag-header .customOneHeaderLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: bold;
  color: #2E2E2E;
}
.custom-ag-header.sort-enabled .customHeaderLabel {
  cursor: pointer;
}
.custom-ag-header .customSortDownLabel.active {
  display: block;
}
.custom-ag-header .customSortDownLabel.inactive {
  display: none;
}
.custom-ag-header .customSortUpLabel.active {
  display: block;
}
.custom-ag-header .customSortUpLabel.inactive {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-distribution/esp-column-header/esp-column-header.component.scss","webpack://./../PSR%20ESP%20WebApp%20Build/src/app/pages/plan/plan-distribution/esp-column-header/esp-column-header.component.scss"],"names":[],"mappings":"AAAA;EAaE,aAAA;EACA,qBAAA;ACXF;ADFE;EACE,gBAAA;EACA,uBAAA;EACA,yBAAA;EACA,iBAAA;EACA,cAAA;ACIJ;ADDI;EACE,eAAA;ACGN;ADGI;EACE,cAAA;ACDN;ADGI;EACE,aAAA;ACDN;ADKI;EACE,cAAA;ACHN;ADKI;EACE,aAAA;ACHN","sourcesContent":[".custom-ag-header {\n  .customOneHeaderLabel {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    text-transform: uppercase;\n    font-weight: bold;\n    color: #2E2E2E;\n  }\n  &.sort-enabled {\n    .customHeaderLabel {\n      cursor: pointer;\n    }\n  }\n  display: flex;\n  flex-flow: row nowrap;\n  .customSortDownLabel {\n    &.active {\n      display: block;\n    }\n    &.inactive {\n      display: none;\n    }\n  }\n  .customSortUpLabel {\n    &.active {\n      display: block;\n    }\n    &.inactive {\n      display: none;\n    }\n  }\n}",".custom-ag-header {\n  display: flex;\n  flex-flow: row nowrap;\n}\n.custom-ag-header .customOneHeaderLabel {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  font-weight: bold;\n  color: #2E2E2E;\n}\n.custom-ag-header.sort-enabled .customHeaderLabel {\n  cursor: pointer;\n}\n.custom-ag-header .customSortDownLabel.active {\n  display: block;\n}\n.custom-ag-header .customSortDownLabel.inactive {\n  display: none;\n}\n.custom-ag-header .customSortUpLabel.active {\n  display: block;\n}\n.custom-ag-header .customSortUpLabel.inactive {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
