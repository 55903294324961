// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `i {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/grid-item-action/grid-item-action.component.scss","webpack://./../PSR%20ESP%20WebApp%20Build/src/app/components/grid-item-action/grid-item-action.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;ACCF","sourcesContent":["i {\n  cursor: pointer;\n}\n","i {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
